import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
    return http({
        url: http.adornUrl('/hbi/reportcategory/list'),
        method: 'get',
        params: params
    })
}

// 删除数据
export function deleteData(ids = []) {
    return http({
        url: http.adornUrl("/hbi/reportcategory/delete"),
        method: "post",
        data: http.adornData(ids, false)
    })
}

// 根据ID 获取
export function getById(id) {
    return http({
        url: http.adornUrl(`/hbi/reportcategory/info/${id}`),
        method: "get"
    })
}

// 更新或新增
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/hbi/reportcategory/${!data.id ? "save" : "update"}`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 全部列表获取
export function getAllList (params) {
    return http({
        url: http.adornUrl('/hbi/reportcategory/getAllList'),
        method: 'get',
        params: params
    })
}