<template>
    <el-dialog
            :title=" '新增'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
            <el-form-item label="用户名" prop="userId"  >
                <el-select v-model="dataForm.userId"  placeholder="用户名" style="width: 100%"  clearable >
                    <el-option :disabled="isDisabled(item.userId)" v-for="item in userList" :key="item.userId" :label="item.username" :value="item.userId">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import { saveOrUpdateUser } from '@/api/hbi/project.js'
    import { getList } from '@/api/sys/user.js'
    export default {
        data () {
            return {
                visible: false,
                isOnSubmit: false,
                formLoading: false,
                dataForm: {
                    projectId: null,
                    userId: ''
                },
                userList: [],
                dataRule: {
                    userId: [
                        { required: true, message: '用户名不能为空', trigger: 'blur' }
                    ]
                }
            }
        },
        props:{
            existList:{
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        created() {
            //获取所有用户
            this.getUserList()
        },
        methods: {
            init (projectId) {
                this.dataForm.projectId = projectId
                this.visible = true
                this.isOnSubmit = false
                this.dataForm.userId = null
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.isOnSubmit = true
                        const data_ = {
                            'userIds': this.dataForm.userId,
                            'projectId': this.dataForm.projectId,
                        }
                        saveOrUpdateUser(data_).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 500
                                })
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        }).catch((err) => {
                            this.$message.error(err)
                        }).finally(()=>{
                            this.isOnSubmit = false
                        })
                    }
                })
            },

            //获取所有用户
            getUserList(){
                getList().then(({ data }) => {
                    if (data && data.code === 0) {
                        this.userList = data.data.list
                    }
                })
            },

            isDisabled(userId){
                for (let item of this.existList) {
                    if(item.userId == userId){
                        return true
                    }
                }
                return false
            }
        }
    }
</script>
