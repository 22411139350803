<template>
  <div>
    <el-dialog
        title="选择报表"
        :close-on-click-modal="false"
        :visible.sync="visible"
        width="65%">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form :inline="true" :model="treeForm">
            <table class="detailTable_search" style="width: 100%">
              <tr>
                <td class="titleTd width80px">分组名称</td>
                <td class="valueTd">
                  <el-input v-model="treeForm.name" placeholder="使用分组名称过滤列表，字母不区分大小写"
                            clearable></el-input>
                </td>
              </tr>
            </table>
          </el-form>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="wxts_msg_search">
            <span>分组展示</span>
            <div class="f_t">
              <el-button-group>
                <el-button @click="getDataList()" :loading="dataTreeLoading">刷新</el-button>
              </el-button-group>
            </div>
          </div>
          <div class="data_tree">
            <el-row :gutter="20">
              <el-col>
                <el-tree
                    :data="dataTree"
                    node-key="id"
                    ref="dataTree"
                    default-expand-all
                    :filter-node-method="filterNode"
                    @current-change="handleCurrentChange"
                    :expand-on-click-node="false"
                    :props="{label:'name', children:'children'}"
                    v-loading="dataTreeLoading">
                  <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
                     <span>{{ node.label }}</span>
                     <span>
                       <el-button type="text" size="mini" @click="modifyNode(node, data)">修改</el-button>
                       <el-button type="text" size="mini" @click="removeNode(data)">删除分组</el-button>
                       <el-button type="text" size="mini" @click="addOrUpdateHandle(data)">追加子分组</el-button>
                     </span>
                   </span>-->
                </el-tree>
              </el-col>
            </el-row>
          </div>

        </el-col>
        <el-col :span="16">
          <el-form :inline="true" :model="dataForm">
            <table class="detailTable_search" style="width: 100%">
              <tr>
                <td class="titleTd width80px">配置名称</td>
                <td class="valueTd">
                  <el-input v-model="dataForm.name" placeholder="配置名称" clearable></el-input>
                </td>
              </tr>
            </table>
          </el-form>

          <div class="wxts_msg_search">
            <span>配置列表{{categoryName ? ('('+categoryName+')') : ''}}</span>
            <div class="f_t">
              <el-button-group>
                <el-button @click="currentChangeHandle(1)" :disabled="dataListLoading">查询</el-button>
              </el-button-group>
            </div>
          </div>
          <el-table :data="dataList" border v-loading="dataListLoading"
                    style="width: 100%;">
            <el-table-column prop="categoryName" header-align="center" align="center" label="分组名称">
            </el-table-column>
            <el-table-column prop="name" header-align="center" align="center" label="配置名称">
            </el-table-column>
            <el-table-column header-align="center" align="center" width="220" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="addHandle(scope.row)">带回</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              @size-change="sizeChangeHandle" class="mod-footer"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalCount"
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {treeDataTranslate} from '@/utils/index.js';
import {getAllList} from '@/api/hbi/reportcategory.js';
import {getReportList} from '@/api/hbi/report.js';
import {saveOrUpdateApp} from '@/api/hbi/project.js';

export default {
  data() {
    return {
      visible: false,
      dataTree: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataTreeLoading: false,
      categoryId: '',
      categoryName: '',
      treeForm: {
        name: '',
      },
      dataForm: {
        name: null,
        reportType: 2,
        publishStatus: 2,
        projectId: null
      },
      isOnSubmit: false,
    }
  },

  methods: {

    init(projectId) {
      this.dataForm.projectId = projectId
      this.visible = true
      this.isOnSubmit = true
      this.treeForm.name = null
      this.dataForm.name = null
      //获取已发布的app
      this.getDataList()
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getReportList();
    },

    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getReportList();
    },

    /**
     *  选中某个分组时
     */
    handleCurrentChange(val) {
      this.categoryId = val.id
      this.categoryName = val.name

      this.currentChangeHandle(1)
      //根据分组ID查询配置列表的数据
    },


    //根据分组ID获取数据
    getReportList() {
      this.dataListLoading = true;
      getReportList({
        categoryId: this.categoryId,
        name: this.dataForm.name,
        reportType: this.dataForm.reportType,
        publishStatus: this.dataForm.publishStatus,
        page: this.pageIndex,
        limit: this.pageSize
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
        }
      })
          .finally(() => {
            this.dataListLoading = false;
          });
    },


    // 获取分组展示数据列表
    getDataList() {
      this.dataTreeLoading = true
      const params_ = {}
      getAllList(params_).then(({data}) => {
        if (data && data.code === 0) {
          const list = data.data
          this.dataTree = list && list.length > 0 ? treeDataTranslate(list, 'id', 'parentId') : [];
          this.categoryName = '';
          this.treeForm.name = '';
          this.categoryId = ''
        } else {
          this.dataTree = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataTreeLoading = false
        //没选择分组时的配置列表
        this.getReportList()
      })
    },

    // 带回
    addHandle(row) {
      this.isOnSubmit = true
      const data_ = {
        'reportId': row.id,
        'projectId': this.dataForm.projectId,
      }
      saveOrUpdateApp(data_).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 500
          })
          this.visible = false
          this.$emit('refreshDataList')
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        this.$message.error(err)
      }).finally(() => {
        this.isOnSubmit = false
      })

    },

    /** 树信息过滤 */
    filterDataList() {
      this.$refs.dataTree.filter(this.treeForm.name);
    },
    /** 树信息过滤实现 */
    filterNode(value, data) {
      if (!value) return true;
      let v = value.toLowerCase()
      let d = data.name.toLowerCase()
      return d.indexOf(v) !== -1;
    },
  }
}
</script>
