<template>
    <div class="hbi-deploy">
        <el-form :model="dataForm" ref="dataForm" v-loading="formLoading" label-width="80px">
            <div class="wxts_msg_search" >
                <span>项目基本信息</span>
                <div class="f_t">
                    <el-button-group>
                        <el-button type="primary" @click="deploy()" :loading="dataListLoading">刷新</el-button>
                        <el-button @click="cancelHandle()" :loading="isOnSubmit">返回</el-button>
                    </el-button-group>
                </div>
            </div>
            <el-form-item label="项目名称" prop="projectName">
                <el-input v-model.trim="dataForm.projectName" placeholder="项目名称" maxlength="200" show-word-limit
                          :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="dataForm.remark"
                          placeholder="备注" maxlength="2000" show-word-limit  :disabled="true"/>
            </el-form-item>

            <div class="wxts_msg_search" >
                <span>菜单列表</span>
                <div class="f_t">
                    <el-button-group>
                        <el-button  type="primary" size="mini"  @click="addOrUpdateMenuHandle()">新增</el-button>
                    </el-button-group>
                </div>
            </div>
            <el-table :data="dataList.menuList" border style="width: 100%;" v-loading="dataListLoading">
                <table-tree-column prop="name" header-align="center" treeKey="menuId"  label="菜单名称">
                </table-tree-column>
                <el-table-column prop="parentName" header-align="center" align="center"  label="上级菜单">
                </el-table-column>
                <el-table-column prop="reportName" header-align="center" align="center"  label="报表名称">
                </el-table-column>
                <el-table-column prop="type" header-align="center" align="center"  label="类型">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type === 0" size="small">目录</el-tag>
                        <el-tag v-else-if="scope.row.type === 1" size="small" type="success">菜单</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="orderNum" header-align="center" align="center"  label="排序号">
                </el-table-column>
                <el-table-column prop="enabled" header-align="center" align="center"
                                 :show-overflow-tooltip="true" label="启用">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.enabled === 1" size="small" type="success">启用</el-tag>
                        <el-tag v-else size="small" type="info">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column header-align="center" align="center" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="isAuth('hbi:hbiprojectmenu:update')" type="text" class="update-button" size="small" @click="addOrUpdateMenuHandle(scope.row.menuId)">修改</el-button>
                        <el-button v-if="isAuth('hbi:hbiprojectmenu:delete')" type="text" class="del-button" size="small" @click="MenuHandle(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div style="height:10px"></div>

            <div class="wxts_msg_search" >
                <span>人员列表</span>
                <div class="f_t">
                    <el-button-group>
                        <el-button  type="primary" size="mini"  @click="addOrUpdateUserHandle()">新增</el-button>
                    </el-button-group>
                </div>
            </div>
            <el-table :data="dataList.userList" border v-loading="dataListLoading">
                <el-table-column prop="projectName" header-align="center" align="center" label="项目名称"></el-table-column>
                <el-table-column prop="userName" header-align="center" align="center" label="用户名称"></el-table-column>
                <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="isAuth('hbi:hbiprojectmenu:delete')" type="text" class="del-button" size="small" @click="deleteUserHandle(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div style="height:10px"></div>

            <div class="wxts_msg_search" >
                <span>App报表列表</span>
                <div class="f_t">
                    <el-button-group>
                        <el-button  type="primary" size="mini"  @click="addOrUpdateAppHandle()">新增</el-button>
                    </el-button-group>
                </div>
            </div>
            <el-table :data="dataList.appList" border v-loading="dataListLoading"  style="width: 100%;">
                <el-table-column prop="projectName" header-align="center" align="center" label="项目名称"></el-table-column>
                <el-table-column prop="reportName" header-align="center" align="center" label="报表名称"></el-table-column>
                <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-if="isAuth('hbi:hbiproject:delete')" @click="deleteAppHandle(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div style="height:10px"></div>

            <div class="wxts_msg_search" >
                <span>大屏列表</span>
                <div class="f_t">
                    <el-button-group>
                        <el-button  type="primary" size="mini"  @click="addOrUpdateBscreenHandle()">新增</el-button>
                    </el-button-group>
                </div>
            </div>
            <el-table :data="dataList.bscreenList" border v-loading="dataListLoading"  style="width: 100%;">
                <el-table-column prop="projectName" header-align="center" align="center" label="项目名称"></el-table-column>
                <el-table-column prop="bscreenName" header-align="center" align="center" label="大屏名称"></el-table-column>
                <el-table-column header-align="center" align="center" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"  @click="deleteBscreenHandle(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>

        <!-- 弹窗, 新增 / 修改 菜单-->
        <add-or-update-menu v-if="addOrUpdateMenuVisible" ref="addOrUpdateMenu" @refreshDataList="deploy"></add-or-update-menu>
        <!--弹窗, 新增 / 修改 用户-->
        <add-or-update-user v-if="addOrUpdateUserVisible" ref="addOrUpdateUser" @refreshDataList="deploy"></add-or-update-user>
        <!--弹窗, 新增 / 修改 app报表-->
        <add-or-update-app v-if="addOrUpdateAppVisible" ref="addOrUpdateApp" @refreshDataList="deploy"></add-or-update-app>
        <!--弹窗, 新增 / 修改 大屏-->
        <add-or-update-bscreen v-if="addOrUpdateBscreenVisible" ref="addOrUpdateBscreen" @refreshDataList="deploy"></add-or-update-bscreen>
    </div>
</template>

<script>
    import AddOrUpdateMenu from './addMenu'
    import AddOrUpdateUser from './addUser'
    import AddOrUpdateApp from './addApp'
    import AddOrUpdateBscreen from './addBscreen'
    import {getList, deleteMenuData, deleteUserData, deleteAppData, deleteBscreenData} from '@/api/hbi/project.js'
    import {treeDataTranslate} from "@/utils/index";
    import TableTreeColumn from "@/components/table-tree-column";

    export default {
        data() {
            return {
                formLoading: false,
                dataForm: {
                    projectId: null,
                    projectName: "",
                    remark: "",
                    menuList: [],
                    userList: [],
                    appList: [],
                    bscreenList: [],
                },
                isOnSubmit: false,
                dataList: [],
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateMenuVisible: false,
                addOrUpdateUserVisible: false,
                addOrUpdateAppVisible: false,
                addOrUpdateBscreenVisible: false,
            }
        },
        components: {
            TableTreeColumn,AddOrUpdateMenu,AddOrUpdateUser,AddOrUpdateApp,AddOrUpdateBscreen
        },
        methods: {
            //获取数据列表
            deploy(project) {
                if (project && project.id) {
                    this.dataForm.projectId = project.id
                    this.dataForm.projectName = project.name
                    this.dataForm.remark = project.remark
                }
                this.dataListLoading = true;
                getList(this.dataForm.projectId).then(({data}) => {
                    if (data && data.code == 0) {
                        const menuList = data.data.menuEntityList;
                        this.dataList.menuList = treeDataTranslate(menuList, "menuId");

                        /*用户列表*/
                        this.dataList.userList = data.data.userEntityList
                        /*app报表列表*/
                        this.dataList.appList = data.data.appEntityList
                        /*大屏列表*/
                        this.dataList.bscreenList = data.data.bscreenEntityList
                    }
                }).finally(() => {
                    this.dataListLoading = false;
                });
            },


            /**
             * 返回上一页
             */
            cancelHandle() {
                this.backMainPage(false);
            },
            /**
             * 关闭当前页返回上一页
             * @param refresh 返回后是否刷新列表
             */
            backMainPage(refresh) {
                this.$emit('refreshDataList', refresh)
            },

            // 新增 / 修改  菜单
            addOrUpdateMenuHandle(id) {
                this.addOrUpdateMenuVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdateMenu.init(id, this.dataForm.projectId)
                })
            },

            // 新增 / 修改  用户
            addOrUpdateUserHandle() {
                this.addOrUpdateUserVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdateUser.init(this.dataForm.projectId)
                })
            },

            // 新增 / 修改  app报表
            addOrUpdateAppHandle() {
                this.addOrUpdateAppVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdateApp.init(this.dataForm.projectId)
                })
            },

            // 新增 / 修改  大屏
            addOrUpdateBscreenHandle() {
                this.addOrUpdateBscreenVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdateBscreen.init(this.dataForm.projectId)
                })
            },

            // 删除 菜单
            MenuHandle(row) {
                const id = row.menuId;
                this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    deleteMenuData(id).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            this.deploy()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                    })
                }).catch(() => {
                })
            },

            // 删除 用户
            deleteUserHandle(row){
                const data_ = {
                    'userIds': row.userId,
                    'projectId': row.projectId,
                }
                this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    deleteUserData(data_).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            this.deploy()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                    })
                }).catch(() => {
                })
            },

            // 删除 app报表
            deleteAppHandle(row){
                const data_ = {
                    'reportId': row.reportId,
                    'projectId': row.projectId,
                }
                this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    deleteAppData(data_).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            this.deploy()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                    })
                }).catch(() => {
                })
            },

            // 删除 大屏
            deleteBscreenHandle(row) {
                const data_ = {
                    'bscreenId': row.bscreenId,
                    'projectId': row.projectId,
                }
                this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    deleteBscreenData(data_).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            this.deploy()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                    })
                }).catch(() => {
                })

            }

            }
    }
</script>
<style lang="scss">
    .hbi-deploy {

    }
</style>
