<template>
    <el-dialog
            :title="!dataForm.id ? '新增菜单' : '修改菜单'"
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            :lock-scroll="false"
            :visible.sync="visible"
    >
        <div :style="{ 'overflow-y': 'scroll' }">
            <el-form :model="dataForm" :rules="dataRule" ref="dataForm" v-loading="loading" label-width="80px">
                <el-form-item label="类型" prop="type">
                    <el-radio-group v-model="dataForm.type">
                        <template v-for="(type, index) in dataForm.typeList">
                            <el-radio v-if="type != ''" :label="index" :key="type">{{
                                type
                                }}
                            </el-radio>
                        </template>
                    </el-radio-group>
                </el-form-item>
                <el-form-item :label="dataForm.typeList[dataForm.type] + '名称'" prop="name">
                    <el-input v-model="dataForm.name" :placeholder="dataForm.typeList[dataForm.type] + '名称'">
                    </el-input>
                </el-form-item>
                <el-form-item label="上级菜单" prop="parentName">
                    <el-popover ref="menuListPopover" placement="bottom-start" v-model="menuSelectShow" trigger="click"
                    >
                        <div style="height:300px;overflow-y:auto;">
                            <el-tree :data="menuList" :props="menuListTreeProps"
                                     node-key="menuId"
                                     ref="menuListTree"
                                     @current-change="menuListTreeCurrentChangeHandle"
                                     :default-expand-all="false"
                                     :accordion="true"
                                     :highlight-current="true"
                                     :expand-on-click-node="false"
                            >
                            </el-tree>
                        </div>
                    </el-popover>
                    <el-input v-model="dataForm.parentName" v-popover:menuListPopover :readonly="true"
                              placeholder="点击选择上级菜单" class="menu-list__input">
                    </el-input>
                </el-form-item>

                <el-form-item label="报表名称" prop="projectId"  v-if="dataForm.type == 1">
                    <el-select v-model="dataForm.reportId"  placeholder="项目名称" style="width: 100%"  clearable >
                        <el-option v-for="item in reportList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="排序号" prop="orderNum">
                    <el-input-number v-model="dataForm.orderNum" controls-position="right" :min="0" label="排序号">
                    </el-input-number>
                </el-form-item>

                <el-form-item label="是否启用">
                    <el-switch v-model="dataForm.enabled" :active-value="1" :inactive-value="0" active-text="启用"
                               inactive-text="禁用">
                    </el-switch>
                </el-form-item>
                <el-form-item v-if="dataForm.type < 3" label="菜单图标" prop="icon">
                    <el-popover ref="iconListPopover" placement="bottom-start" trigger="click"
                                popper-class="mod-menu__icon-popover">
                        <div class="mod-menu__icon-list">
                            <el-button v-for="item in iconList" :key="item.icon" slot="reference"
                                       @click="iconActiveHandle(item.icon)"
                                       :class="{ 'is-active': item.icon === dataForm.icon }">
                                <icon-svg :name="item.icon"></icon-svg>
                            </el-button>
                        </div>
                    </el-popover>
                    <el-input v-model="dataForm.icon" v-popover:iconListPopover placeholder="菜单图标名称"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false">返回</el-button>
            <el-button type="primary" :disabled="loading" @click="dataFormSubmit()">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {treeDataTranslate} from "@/utils/index";
    import iconjson from "@/assets/fonts/iconfont.json";
    import {getById, selectList, saveOrUpdate, getPcList} from '@/api/hbi/project.js'

    export default {
        data() {
            return {
                loading: false,
                visible: true,
                menuSelectShow: false,
                projectId: null,
                dataForm: {
                    id: 0,
                    type: 1,
                    typeList: ["目录", "菜单"],
                    name: "",
                    parentId: 0,
                    parentName: "",
                    orderNum: 0,
                    icon: "",
                    enabled: 1,
                    iconList: [],
                    reportId: ""
                },
                dataRule: {
                    name: [
                        {required: true, message: "菜单名称不能为空", trigger: "blur"}
                    ],
                    parentName: [
                        {required: true, message: "上级菜单不能为空", trigger: "change"}
                    ],
                    reportId: [
                        {required: true, message: "报表不能为空", trigger: "change"}
                    ],
                },
                menuList: [],
                menuListTreeProps: {
                    label: "name",
                    children: "children"
                },
                reportList: []
            };
        },
        computed: {
            //计算内部元素的高度
            asideHeight: function () {
                return document.documentElement["clientHeight"] - 110 - 25;
            }
        },
        // activated () {
        //   const id = this.$route.query.id
        //   this.init(id)
        //   this.loading = false
        // },
        created() {
            const icons = iconjson.glyphs;
            this.iconList = icons.map(t => {
                t.icon = "icon-" + t.font_class;
                return t;
            });

            this.getPcList()
        },
        methods: {
            init(id,projectId) {
                this.dataForm.id = id;
                this.projectId = projectId;
                this.visible = true;

                this.dataForm.reportId = ''
                selectList(this.projectId).then(({data}) => {
                    // 判断当前是菜单还是工具栏

                    this.menuList = treeDataTranslate(data.data, "menuId");
                }).then(() => {
                        this.$nextTick(() => {
                            this.$refs["dataForm"].resetFields();
                        });
                    }).then(() => {
                        if (!this.dataForm.id) {
                            // 新增
                            this.menuListTreeSetCurrentNode();
                        } else {
                            // 修改

                            getById(this.dataForm.id).then(({data}) => {
                                this.dataForm.id = data.data.menuId;
                                this.dataForm.type = data.data.type;
                                this.dataForm.name = data.data.name;
                                this.dataForm.parentId = data.data.parentId;
                                this.dataForm.perms = data.data.perms;
                                this.dataForm.orderNum = data.data.orderNum;
                                this.dataForm.icon = data.data.icon;
                                this.dataForm.enabled = data.data.enabled;
                                this.dataForm.reportId = data.data.reportId;

                                this.menuListTreeSetCurrentNode();
                            });
                        }
                    });
                this.getPcList()
            },
            // 菜单树选中
            menuListTreeCurrentChangeHandle(data, node) {
                this.dataForm.parentId = data.menuId;
                this.dataForm.parentName = data.name;

                this.menuSelectShow = false;
            },
            // 菜单树设置当前选中节点
            menuListTreeSetCurrentNode() {
                this.$refs.menuListTree.setCurrentKey(this.dataForm.parentId);
                this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() ||
                    {})["name"];
            },
            // 图标选中
            iconActiveHandle(iconName) {
                this.dataForm.icon = iconName;
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs["dataForm"].validate(valid => {
                    if (valid) {
                        this.loading = true;

                        const data_ = {
                            menuId: this.dataForm.id || undefined,
                            type: this.dataForm.type,
                            name: this.dataForm.name,
                            parentId: this.dataForm.parentId,
                            url: this.dataForm.url,
                            enabled: this.dataForm.enabled,
                            orderNum: this.dataForm.orderNum,
                            icon: this.dataForm.icon,
                            projectId: this.projectId,
                            reportId: this.dataForm.reportId
                        }
                        saveOrUpdate(data_).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                    duration: 1000
                                });

                                this.visible = false;
                                this.$emit("refreshDataList");
                            } else {
                                this.$message.error(data.msg);
                            }
                        })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                });
            },

            //获取已发布的pc端报表
            getPcList(){
                getPcList().then(({ data }) => {
                    if (data && data.code === 0) {
                        this.reportList = data.data
                    }
                })
            },
        }
    };
</script>

<style lang="scss">
    .mod-menu {
        .menu-list__input,
        .icon-list__input {
            > .el-input__inner {
                cursor: pointer;
            }
        }
        &__icon-popover {
            max-width: 450px;
        }
        &__icon-list {
            max-height: 240px;
            padding: 0;
            overflow-y: auto;
            margin: -8px 0 0 -8px;

            > .el-button {
                padding: 8px;
                margin: 8px 0 0 8px;
                > span {
                    display: inline-block;
                    vertical-align: middle;
                    width: 24px;
                    height: 24px;
                    font-size: 26px;
                }

                .iconfont {
                    font-size: 26px;
                }
            }
        }
        .icon-list__tips {
            font-size: 18px;
            text-align: center;
            color: #e6a23c;
            cursor: pointer;
        }

        .hover-iconfont {
            font-size: 30px;
        }
    }
</style>
